import React, { FC, useState, useEffect } from "react";
import { Button, Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetRequirements, useGetRequirementsbuyer } from "../utils/hooks";
import { AuthTokenType,Requirement} from "../utils/types";
import { getAuthToken, getRequirements, getRequirementsbuyer } from "../utils/functions";
import axios from "axios";
import { PChatbotUrl, RequirementbURL, Requirementduplicate, RequirementURL } from "../utils/network";
import ContentLayoutTabs from "../components/ContentLayoutTabs";
import AddRequirements from "./AddRequirements";
import RequirementDrawer from "./RequirementsDrawer";
import EditRequirements from "./EditRequirements";

const RequirementsBuyers: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook


  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Requirement Type"), // Translate the title
      dataIndex: "requirement_type",
      key: "requirement_type",
    },    
    {
      title: t("Business Line"), // Translate the title
      dataIndex: ["business_line","name"],
      key: ["business_line","name"],
    },    
    {
      title: t("Category"), // Translate the title
      dataIndex: "category",
      key: "category",
    },    
    {
      title: t("Sub Category"), // Translate the title
      dataIndex: "subcategory",
      key: "subcategory",
    },    


    {
      title: t("Sub Supplies"), // Translate the title
      dataIndex: "sub_supplies",
      key: "sub_supplies",
    },
    {
      title: t("Requirement Id"), // Translate the title
      dataIndex: "r_id",
      key: "r_id",
    },
    {
      title: t("Buyer"), // Translate the title
      dataIndex: "buyer1",
      key: "buyer1",
    },
    {
      title: t("Status"), // Translate the title
      dataIndex: "status1",
      key: "status1",
    },
    {
      title: t("PO Number"), // Translate the title
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: t("AVailable Budget"), // Translate the title
      dataIndex: "net_value",
      key: "net_value",
    },

    {
      title: t("Currency"), // Translate the title
      dataIndex: "currency1",
      key: "currency1",
    },
    {
      title: t("Supplier"), // Translate the title
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: t("Project"), // Translate the title
      dataIndex: "project1",
      key: "project1",
    },
    {
      title: t("Item Description"), // Translate the title
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: t("Original Date"), // Translate the title
      dataIndex: "original_date1",
      key: "original_date1",
    },
    
    {
      title: t("Days from Req"), // Translate the title
      dataIndex: "days",
      key: "days",
    },

    {
      title: t("Process Categorization"), // Translate the title
      dataIndex: "process_categorization1",
      key: "process_categorization1",
    },

    {
      title: t("Process Specification"), // Translate the title
      dataIndex: "process_specification1",
      key: "process_specification1",
    },
    {
      title: t("Project Manager"), // Translate the title
      dataIndex: "project_manager1",
      key: "project_manager1",
    },   
    {
      title: t("Requester"), // Translate the title
      dataIndex: "requester1",
      key: "requester1",
    },
    {
      title: t("CECO"), // Translate the title
      dataIndex: "ceco1",
      key: "ceco1",
    },
  
    {
      title: t("Client"), // Translate the title
      dataIndex: "projectclient",
      key: "projectclient",
    },
    {
      title: t("Project MW"), // Translate the title
      dataIndex: "projectmw",
      key: "projectmw",
    },
    {
      title: t("Budget Code"), // Translate the title
      dataIndex: "budgetcode1",
      key: "budgetcode1",
    },

    {
      title: t("Delivery Term"), // Translate the title
      dataIndex: "deliveryterm1",
      key: "deliveryterm1",
    },
    {
      title: t("Cerrada"), // Translate the title
      dataIndex: "cerrada",
      key: "cerrada",
    },
    {
      title: t("CECO Owner's Approval"), // Translate the title
      dataIndex: "ceco_owner_approval",
      key: "ceco_owner_approval",
    },
    {
      title: t("Manager's Approval"), // Translate the title
      dataIndex: "manager_approval",
      key: "manager_approval",
    },
    {
      title: t("Controller's Approval"), // Translate the title
      dataIndex: "controller_approval",
      key: "controller_approval",
    },



    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: Requirement) => (
        
                  <span>
                                <a onClick={() => handleduplicate(record.id)}>{t("Duplicate")}</a>

<span> | </span>

            <a onClick={() => handleViewFiles(record)}>{t("View")}</a>

        <span> | </span>

          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
        </span>
      ),
    },
  ];

  const [Requirements, setRequirements] = useState<Requirement[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingRequirements, setEditingRequirements] = useState<Requirement | null>(null);
  const [drawerVisible1, setDrawerVisible1] = useState(false);
  const [selectedrequirementId, setSelectedrequirementId] = useState<Requirement | null>(null);
  
  const handleViewFiles = (record:Requirement) => {
    setSelectedrequirementId(record);
    setDrawerVisible1(true);
  };
  useGetRequirementsbuyer(setRequirements, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingRequirements) {
      setDrawerVisible(true);
    }
  }, [editingRequirements]);

  const handleEdit = (record: Requirement) => {
    setEditingRequirements(record);
  };

  const handleduplicate = (RequirementId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to Create a Copy?"), // Translate the content
      onOk: async () => {
        try {
          const datatosend: any = {
            id:RequirementId,
          };
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          const response = await axios.post(Requirementduplicate, datatosend, headers);

          // After successful deletion, fetch updated locations
          getRequirementsbuyer(setRequirements, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Copy Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingRequirements(null); // Reset editingCategory when form is closed without editing
  };



  return (
    <div>
    <div>
    <ContentLayoutTabs
      pageTitle={t("Buyer's View")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={Requirements as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}

    >
      <EditRequirements
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getRequirementsbuyer(setRequirements, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingRequirement={editingRequirements}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />


    </ContentLayoutTabs>
      </div>  
      <RequirementDrawer
        requirement={selectedrequirementId}
        visible={drawerVisible1}
        onClose={() => {
          setSelectedrequirementId(null);
          setDrawerVisible1(false);
        }}
      />
      </div>
  );
  
};

export default RequirementsBuyers;