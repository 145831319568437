import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Select } from "antd";
import { AuthTokenType, DataProps,AddRequirementFormProps, ProcessCategorization, ProcessSpecification, CeCo, Projectp, BudgetCode, Status, DeliveryTerm, currency, UserProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs"; // Import dayjs
import {RequirementURL } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useGetBudgetCodes, useGetUsers, useGetCeCos, useGetCurrency, useGetDeliveryTerms, useGetProcessCategorizations, useGetProcessSpecifications, useGetProjectp, useGetStatuses, useGetbuyers, useGetrequesters } from "../utils/hooks";
const { Option } = Select;

const EditRequirements: FC<AddRequirementFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingRequirement,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [processcategorizations, setprocesscategorizations] = useState<ProcessCategorization[]>([]);
  useGetProcessCategorizations(setprocesscategorizations, setFetching);

  const [processspecification, setprocessspecification] = useState<ProcessSpecification[]>([]);
  useGetProcessSpecifications(setprocessspecification, setFetching);

  const [ceco, setceco] = useState<CeCo[]>([]);
  useGetCeCos(setceco, setFetching);

  const [buyer, setbuyer] = useState<UserProps[]>([]);
  useGetbuyers(setbuyer, setFetching);

  const [requester, setrequester] = useState<UserProps[]>([]);
  useGetUsers(setrequester, setFetching);


  const [project, setproject] = useState<Projectp[]>([]);
  useGetProjectp(setproject, setFetching);

  const [budgetcode, setbudgetcode] = useState<BudgetCode[]>([]);
  useGetBudgetCodes(setbudgetcode, setFetching);

  const [status, setstatus] = useState<Status[]>([]);
  useGetStatuses(setstatus, setFetching);

  const [deliveryterms, setdeliveryterms] = useState<DeliveryTerm[]>([]);
  useGetDeliveryTerms(setdeliveryterms, setFetching);

  const [currrency, setcurrency] = useState<currency[]>([]);
  useGetCurrency(setcurrency, setFetching);


  useEffect(() => {
    if (editingRequirement) {
      form.setFieldsValue({...editingRequirement,
        process_categorization_id: editingRequirement.process_categorization?.id,
        process_specification_id: editingRequirement.process_specification?.id,
        ceco_id: editingRequirement.ceco?.id,
        buyer_id: editingRequirement.buyer?.id,
        requester_id: editingRequirement.requester?.id,
        project_id: editingRequirement.project?.id,
        budget_code_id: editingRequirement.budget_code?.id,
        status_id: editingRequirement.status?.id,
        currency_id: editingRequirement.currency?.id,
        delivery_term_id: editingRequirement.delivery_term?.id,
        original_date: editingRequirement.original_date ? dayjs(editingRequirement.original_date) : null,

      });
    } else {
      form.resetFields();
    }
  }, [editingRequirement, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingRequirement) {
        // Editing location
        response = await axios.put(`${RequirementURL}${editingRequirement.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(RequirementURL + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  const designation = localStorage.getItem('token5');

  return (
    <Drawer
      title={editingRequirement ? t("Edit Requirement") : t("Add Requirement")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Code")} 
          name="r_id"
          rules={[{ required: true, message: t('Please input the Requirement ID!') }]}
        >
          <Input placeholder={t("Requirement ID")}   /> 
        </Form.Item>


      {designation === "controller" && (
  <>
      <Form.Item
        label={t("Process Specification")}
        name="process_specification_id"
        rules={[{ required: true, message: t('Please input the Process Specification!') }]}
      >
          <Select placeholder={t("Process Specification")} >
            {
              processspecification.map((item, index) => <Option value={item.id} key={index}>{item.name}</Option>)
            }
          </Select>
      </Form.Item>
      </>
)}



      <Form.Item
        label={t("Buyer")}
        name="buyer_id"
        rules={[{ required: true, message: t('Please input the Buyer!') }]}
      >
          <Select placeholder={t("Buyer")}  >
            {
              buyer.map((item, index) => <Option value={item.id} key={index}>{item.fullname}</Option>)
            }
          </Select>
      </Form.Item>


      <Form.Item
        label={t("PO Number")}
        name="po_number"
        rules={[{ required: false, message: t('Please input the PO Number!') }]}
      >
        <Input placeholder={t("PO Number")} />
      </Form.Item>


      <Form.Item
        label={t("Approval")}
        name="controller_approval"
        rules={[{ required: true, message: t('Please Approve or Reject!') }]}
      >
          <Select placeholder="Approval">
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>

      </Form.Item>

      {designation === "buyer" && (
  <>
    <Form.Item
      label={t("Status")}
      name="status_id"
      rules={[{ required: true, message: t('Please input the Status!') }]}
    >
      <Select placeholder={t("Status")}>
        {status.map((item, index) => (
          <Option value={item.id} key={index}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
          label={t("Available Budget")}
          name="net_value"
          rules={[{ required: true, message: t('Please input the Available Budget!') }]}
        >
          <Input type="number" placeholder={t("Available Budget")} />
        </Form.Item>


    <Form.Item
      label={t("Supplier")}
      name="supplier"
      rules={[{ required: true, message: t('Please input the Supplier!') }]}
    >
      <Input placeholder={t("Supplier")} />
    </Form.Item>
  </>
)}

        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingRequirement ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditRequirements;