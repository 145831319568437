import React, { useEffect, useState } from "react";
import { Drawer, Tree, Button, Table } from "antd";
import { DownloadOutlined, FolderOutlined, FileOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType, Requirement } from "../utils/types";
import { purchaseorderfilesUrl, requirementfilesURL } from "../utils/network";
import FileSaver from "file-saver";
import moment from "moment";

interface FileData {
  id: number;
  requirement: string;
  folder: any;
  pdf_file: string;
  created_at: string;
}

interface RequirementItem {
  id: number;
  product: string;
  quantity: number;
  requirement_date: string;
  unit: number;
}



interface RequirementDrawerProps {
  requirement: Requirement|null;
  visible: boolean;
  onClose: () => void;
}

const RequirementDrawer: React.FC<RequirementDrawerProps> = ({ requirement, visible, onClose }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileData[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);

  const fetchFiles = async () => {
    try {
      const data = { requirement_id: requirement?.id };
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post<FileData[]>(requirementfilesURL + '/get_files_by_requirement/', data, headers);
      setFiles(response.data);
      const uniqueFolders = Array.from(new Set(response.data.map((file: FileData) => file.folder)));
      const newTreeData = uniqueFolders.map((folder: string) => ({
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FolderOutlined style={{ color: "#1890ff", marginRight: 8 }} />
            <span>{folder}</span>
          </div>
        ),
        key: folder,
        children: response.data
          .filter((file: FileData) => file.folder === folder)
          .map((file: FileData) => ({
            title: (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <FileOutlined style={{ color: "#52c41a", marginRight: 8 }} />
                <span style={{ flex: 1 }}>{file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1)}</span>
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(file.pdf_file, file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1))}
                />
              </div>
            ),
            key: file.pdf_file,
          }))
      }));
      setTreeData(newTreeData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const columns = [
    {
      title: t("Product"),
      dataIndex: "product",
      key: "product",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("Requirement Date"),
      dataIndex: "requirement_date",
      key: "requirement_date",
      render: (text: string) => moment(text).format("MM-DD-YYYY"), // Format the date
    },
    {
      title: t("Unit"),
      dataIndex: "unit",
      key: "unit",
    },
  ];

  useEffect(() => {
    if (visible && requirement) {
      fetchFiles();
    }
  }, [visible, requirement]);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      });
  
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };  

  return (
    <Drawer
      title={`${t('Details for Requirement ID')} ${requirement ? requirement.id : ''}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        {requirement && (
          <>
            <Table
              columns={columns}
              dataSource={requirement.requirement_items}
              rowKey="id"
              pagination={false}
            />
            <br/>
            <br/>
            
            <Tree
              showIcon
              defaultExpandAll={false}
              treeData={treeData}
              switcherIcon={<FolderOutlined style={{ color: "#1890ff" }} />}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default RequirementDrawer;